import React from "react"
import "./Resume.css"
import ResumeApi from "./ResumeApi"
import Card from "./Card"
import Blog from "../Blog/Blog"
import Contact2 from "../Contact2/Contact2"

const Resume = () => {
  return (
    <>
    <section className='Resume' id='resume'>
        <div className='container top'>
          <div className='heading text-center'>
           {/*  <h4>10+ YILLIK TECRÜBE</h4> */}
            <br /><br />
           {/*  <h1>Hakkımda</h1> */}
            <h2>Dr. Ali Kemal DEMEZ</h2>
          
            <img src="/img/akdemez4.jpg" alt="" />
         
          </div>

          {/* <div className='content-section mtop d_flex'>
                    
          </div> */}
          <div className='content-section mtop '>
            <p className="leftresume">Hatay’da doğdu ve Lise Eğitimini Hatay’da tamamladı.</p><br/>
            <p className="leftresume">Ankara’da Tıp Fakültesini bitirdi.</p><br/>
            <p className="leftresume">Bingöl ve Şırnak illerinde Pratisyen Hekimlik yaptı.</p><br/>
            <p className="leftresume">İstanbul’da Beyin ve Sinir Cerrahisi Kliniği bünyesinde çalıştı ve bir süre Asistanlık Eğitimi aldı.</p><br/>
            <p className="leftresume">Kendi yapısına daha uygun olduğunu ve insanlara, topluma daha verimli olacağını düşündüğü Danışmanlık alanına yöneldi.</p><br/>
            <p className="leftresume">Danışmanlık alanında çeşitli Eğitimler ve Kurslar aldı.</p><br/>
            <p className="leftresume">Kurulumunda önemli ve etkin rol oynadığı PsikoHayal Danışmanlık Merkezi’nde çalışmaya başladı.</p><br/>            
          </div>
        </div>
      </section>
    
      
      <Blog/>
      <Contact2/>
    </>
    
  )
}

export default Resume