import React from "react"
import "./Porfolio2.css"


const Portfolio = () => {

  
  return (
    <>
      <section className='Portfolio top' id='portfolio'>
        <div className='container'>
          <div className='heading text-center '>
            <h4>BİZİ YOUTUBE'DA TAKİP EDİN</h4>
            <h1>Youtube</h1>
          </div>
          <marquee behavior="scroll" direction="left" scrollamount="10">İçerik çalışmalarımız devam etmektedir!!</marquee>
          
       
        </div>
      </section>
     
    </>
  )
}

export default Portfolio
