import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css'
import Header from './Component/Head/Header'
import Blog from './Component/Blog/Blog'
import Features from './Component/Features/Features'
import Home from './Component/Hero/Home'
import Porfolio2 from './Component/Porfolio2/Porfolio2'
import Resume from './Component/Resume/Resume'
import Testimonial from './Component/Testimonial/Testionial'
import Accordion from './Component/Works/Works'
import Contact2 from './Component/Contact2/Contact2'
import Section from "./Component/Section/Section";



function App() {
  return (
    <div className="App">
     <BrowserRouter>
     <Header/>
     <Routes>      
      <Route path="/blog" element={<Blog/>} />
      <Route path="/contact2" element={<Contact2/>} />
      <Route path="/features" element={<Features/>} />
      <Route path="/" element={<Home/>} />
      <Route path="/porfolio" element={<Porfolio2/>} />
      <Route path="/resume" element={<Resume/>} />
      <Route path="/clients" element={<Testimonial/>} />
      <Route path="/works" element={<Accordion/>} />
      <Route path="/contact2" element={<Contact2/>} />
      <Route path="/section" element={<Section/>} />
      
      
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;

