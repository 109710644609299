
import React from 'react';
import './Section.css';
 const Section = () => {
  const areas = [
    { id: 1, name: 'Aile İçi Çatışmalar' },
    { id: 2, name: 'İlişki Problemleri' },
    { id: 3, name: 'İletişim - Uyum Problemleri' },
    { id: 4, name: 'Evlilik Öncesi Danışmanlık' },
    { id: 5, name: 'Boşanma Süreçleri' },
    { id: 6, name: 'Ergenlik Dönemi Problemleri' },
    { id: 7, name: 'Oyun - İnternet Bağımlılığı' },
    { id: 8, name: 'Sınav Kaygısı' },
    { id: 9, name: 'Depresyon - Kronik Mutsuzluk' },
    { id: 10, name: 'Kaygı - Takıntı - Stres' },
    { id: 11, name: 'Travma - Kayıp - Yas' },
    { id: 12, name: 'Panik Bozukluk' },
    { id: 13, name: 'Öfke Kontrolü' },
    { id: 14, name: 'Fobik Bozukluklar' },
    { id: 15, name: 'Tıbbi Danışmanlık' },
  ];
   return (
    <div className='container'>
      <table className="table">
        <thead>
          <tr>
            <th style={{width:"70px", textAlign:"center"}}>S.N.:</th>
            <th>Çalışma Alanlarımız</th>
          </tr>
        </thead>
        <tbody>
          {areas.map((area) => (
            <tr key={area.id}>
              <td style={{width:"70px", textAlign:"center"}} >{area.id}</td>
              <td > {area.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
 export default Section;