const TestimonialApi = [
    {
      id: 1,
      image: "img/d1.png",
      design: "Yetişkin Danışmanlığı",
      name: "M.V.",
      offcer: "Öğretmen",
      post: "Yetişkin Danışmanlığı",
      date: "Seanslar - Ocak 4, 2023 - Mart 30, 2023",
      desc: "Ali Hocamla birlikte katıltığım seanslardan sonra problemlerinden kurtuldum, Hayatıma başış açım değişti, ",
    },
    {
      id: 2,
      image: "/img/d2.png",
      design: "Kaygı Bozukluğu",
      name: "E.K",
      offcer: "Ev Hanımı",
      post: "Kaygı Bozukluğu Danışmanlığı",
      date: "Seanslar - Ocak 4, 2023 - Mart 30, 2023",
      desc: "Özellikle çocuklar üzerine çok kaygılarım vardı, hayatla ilgili herşeyden kaygı duyuyordum. Ali Kemal Bey(le yapmış olduğumuz seanslardan sonra tüm problemlerin çözüme kavuştu. Teşekkür ederim.",
    },
    {
      id: 3,
      image: "/img/d3.png",
      design: "Aile Danışmanlığı",
      name: "T. Ailesi",
      offcer: "5 yıllık evli çift",
      post: "Aile Danışmanlığı",
      date: "Seanslar - Ocak 4, 2023 - Mart 30, 2023",
      desc: "Evlilik içi uyum sorumlarımız vardı. Birbirimizi anlamıyorduk. Daha doğrusu evliliğin ne demek olduğunu bilmiyorduk. Ali Hocamın seansları sayesinde birbirimize daha çok sahip çıkmaya başladık ve evliliğimizi kurtardık. Ali Hocama teşekkür ederiz.",
    },
    {
      id: 4,
      image: "/img/d4.png",
      design: "Eğitim Koçluğu",
      name: "Merve T.",
      offcer: "Öğrenci",
      post: "Eğitim Koçluğu",
      date: "Seanslar - Ocak 4, 2023 - Mart 30, 2023",
      desc: "Üniversite sınavanı hazırlık döneminde almış olduğum eğitim koçluğu bana 9 Eylül Bilgisayar Mühendisliğini kazandırdı. Tabi bu süreçte Ali Hocamın etkisi çok fazla, benim başımda bir anne - baba gibi tüm dönemi takip etti, emeği fazla, Teşekküler Ali Hocam, iyiki varsın",
    },
    {
      id: 5,
      image: "/img/d5.png",
      design: "Eğitim Koçluğu",
      name: "Seyfi T.",
      offcer: "Öğrenci",
      post: "Eğitim Koçluğu",
      date: "Seanslar - Ocak 4, 2023 - Mart 30, 2023",
      desc: "Lise sınavlarına hazırlanıyorum, Ali hocam ile hafta bir görüşüp ödev takip yapıyor, Annem-Babam böyle takip etmiyor. Ama Seanslara katılmaya başladıktan 2 ay sonra derslerimde belirgin iyileşmeler oldu. Ali Hocama teşekkür ederim	.",
    },
  ]
  
  export default TestimonialApi