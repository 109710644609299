import React, {useState} from 'react'
import './Header.css'
import { Link } from 'react-router-dom'

const Header = () => {
      // fixed Header
  window.addEventListener("scroll", function () {
    const header = document.querySelector(".header")
    header.classList.toggle("active", window.scrollY > 100)
  })

  // Toogle Menu
  const [Mobile, setMobile] = useState(false)
    

    return (
      <>
        <header className='header'>
          <div className='container d_flex'>
            <div className='logo'>
              
           
              <Link to='/'>
             <img src="/img/logoyeni3.png" alt="" />
             
              </Link>
            
            </div>
  
            <div className='navlink'>
              {/* <ul className='link f_flex uppercase'> */}
              <ul className={Mobile ? "nav-links-mobile" : "link f_flex uppercase"} onClick={() => setMobile(false)}>

             
                <li>
                  <Link to='/'>Ana Sayfa</Link>
                </li>
            {/*     <li>
                  <Link to='/features'>Alanlar</Link>
                </li> */}
                <li>
                  <Link to='/works'>HİZMETLERİMİZ</Link>
                </li>
                <li>
                  <Link to='/section'>ÇALIŞMA ALANLARIMIZ</Link>
                </li>
                {/* <li>
                  <Link to='/porfolio'>Youtube</Link>
                </li> */}
                <li>
                  <Link to='/resume'>Hakkımızda</Link>
                </li>
              {/*   <li>
                  <Link to='/clients'>Danışanlar</Link>
                </li> */}
               {/*  <li>
                  <Link to='/blog'>Blog</Link>
                </li> */}
                <li>
                  <Link to='/contact2'>İLETİŞİM</Link>
                </li>
               {/*  <li>
                  <Link to='/contact2'>Randevu</Link>
                </li> */}
                <li>
                <a id="zl-url" class="zl-url" href="https://www.doktortakvimi.com/ali-kemal-demez/pratisyen-aile-danismani/ankara" rel="nofollow" data-zlw-doctor="ali-kemal-demez" data-zlw-type="big_with_calendar" data-zlw-opinion="false" target='_blank' data-zlw-hide-branding="true" data-zlw-saas-only="false"><button className='home-btn'>RANDEVU AL</button></a>
                  
                </li>
              </ul>
            
  
              <button className='toggle' onClick={() => setMobile(!Mobile)}>
              {Mobile ? <i className='fas fa-times close home-btn'></i> : <i className='fas fa-bars open'></i>}
              </button>
            </div>
          </div>
        </header>
        <section className='demo'>
          
        </section>
        
      </>
    )
  }
export default Header
