import React, { useState } from "react";
import './works.css'
import Contact2 from "../Contact2/Contact2";
import Features from "../Features/Features";


const dataCollection = [
    {
        question: "Depresyon",
        answer: "Deprosyanla ilgili içerikler"
    },
    {
        question: "Kaygı Bozukluğu",
        answer: "Kaygı Bozukluğu ile ilgili içerikler"
    },
    {
        question: "Travma",
        answer: "Travma ile ilgili içerikler"
    },
    {
        question: "Panik Bozukluk,",
        answer: "Panik Bozukluk ile ilgili içerikler"
    },
    {
        question: "Obsesyon(Takıntı)",
        answer: "Obsesyon(Takıntı)ile ilgili içerikler"
    },
    {
        question: "Uyum Problemleri",
        answer: "Uyum Problemleri ile ilgili içerikler"
    },
    {
        question: "İletişim Problemleri,",
        answer: "Deproİletişim Problemleri ile ilgili içerikler"
    },
    {
        question: "Cinsel Problemler",
        answer: "Cinsel Problemler ile ilgili içerikler"
    },
    {
        question: "Boşanma Süreçleri",
        answer: "Boşanma Süreçleri ile ilgili içerikler"
    },
    {
        question: "Tıbbi Danismanlik",
        answer: "Tıbbi Danismanlik ile ilgili içerikler"
    },
    {
        question: "Psikolojik Testler",
        answer: "Psikolojik Testler ile ilgili içerikler"
    },
    {
        question: "Online Danışmanlık Hizmeti",
        answer: "Tüm danışanlarımıza online danışmalık hizmeti vermekteyiz."
    }
];



function Accordion() {
    const [accordion, setActiveAccordion] = useState(-1);


    function toggleAccordion(index) {
        if (index === accordion) {
            setActiveAccordion(-1);
            return
        }
        setActiveAccordion(index);
    }

    return (
        <>

        <Features/>
        {/*     <div className="container">
                <div>
                    <span className="accordion__title">Hizmet Alanlarımız</span>
                    <h1>Hizmet alanlarımızı incele</h1>

                </div>
                <div className="accordion__faq">
                    {dataCollection.map((item, index) =>
                        <div key={index} onClick={() => toggleAccordion(index)}>
                            <div className="accordion__faq-heading">
                                <h3 className={accordion === index ? "active" : ""}>{item.question}</h3>

                                <div>
                                    {accordion === index ? (
                                        <>
                                            {" "}
                                            <span className="verticle">-</span>
                                        </>
                                    ) : (
                                        <>
                                            {" "}
                                            <span className="verticle">+</span>
                                        </>
                                    )
                                    }
                                </div>
                            </div>
                            <div>
                                <p className={accordion === index ? "active" : "inactive"}>{item.answer}</p>
                            </div>

                        </div>
                    )}
                </div>
            </div> */}
            <Contact2/>

        </>
    )

}

export default Accordion