
import React from "react";
import { Link } from "react-router-dom";
import '../Component/Footer.css';
 const Footer = () => {
  return (
    <>
      <footer className="padding">
        <div className="footer-row">
          <div className="footer-col">
            <img src="img/logoyeni1.png" alt="" />
            <p></p>
          </div>
          <div className="footer-col">
            <h2>Linkler</h2>
            <div className="divider"></div>
            <div className="icon-list">
              <Link to="/"><i className="fa-solid fa-angle-right"></i>Ana Sayfa</Link>
              <Link to="/works"><i className="fa-solid fa-angle-right"></i>Hizmetlerimiz</Link>
              <Link to="/section"><i className="fa-solid fa-angle-right"></i>Çalışma Alanlarımız</Link>
              <Link to="/porfolio"><i className="fa-solid fa-angle-right"></i>Youtube Kanalı</Link>
              <Link to="/resume"><i className="fa-solid fa-angle-right"></i>Hakkımızda</Link>
              <Link to="https://www.doktortakvimi.com/ali-kemal-demez/pratisyen-aile-danismani/ankara"><i className="fa-solid fa-angle-right"></i>Randevu Al</Link>
            </div>
          </div>
          <div className="footer-col">
            <h2>Sosyal Medya</h2>
            <div className="divider"></div>
            <div className="icon-list">
              <Link to="#"><i className="fa-solid fa-angle-right"></i>Twitter</Link>
              <Link to="#"><i className="fa-solid fa-angle-right"></i>Youtube</Link>
              <Link to="#"><i className="fa-solid fa-angle-right"></i>Instagram</Link>
            </div>
          </div>
          <div className="footer-col">
            <h2>İletişim</h2>
            <div className="divider"></div>
            <div className="icon-list">
              <Link to="#"><i className="fa-solid fa-phone"></i>+90 555 844 00 10</Link>
              <Link to="#"><i className="fa-solid fa-envelope-open-text"></i>info@psikohayal.com</Link>
              <Link to="#"><i className="fa-solid fa-location-dot"></i>Turkey</Link>
            </div>
          </div>
        </div>
        <div className="credit">
          <p>&copy; CopyRight 2023 | Designed By <Link to="https://web-developer-emrah.vercel.app/">eturk64</Link></p>
        </div>
      </footer>
    </>
  );
};
 export default Footer;