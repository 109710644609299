import {useRef} from "react";
import './Contact2.css'
import emailjs from '@emailjs/browser';
//import contact1 from '../../../src/Component/img/contact-us-gd5f717d1b_640.jpg'
import Footer from '../Footer'

const Contact2 = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    //service_uie0zxa template_x6e4pue DzGkBv9BT_El3Rd9X emailjs.sendForm('service_hn0ykp2', 'template_3goiro9', form.current, 'jNiZ91aw5HdnkIu63')
        emailjs.sendForm('service_uie0zxa', 'template_x6e4pue', form.current, 'DzGkBv9BT_El3Rd9X')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };
    return (
      <>
        <section className='Contact' id='contact'>
        <div className='container top'>
          <div className='heading text-center'>
            <h4>İLETİŞİM</h4>
            <h1>Bizimle İletişime Geçin</h1>
            <h4>Şu an sadece Online Danışmanlık Hizmeti verilmektedir.</h4>
          </div>

          <div className='content d_flex'>
            <div className='left'>
              <div className='box box_shodow'>
                <div className='img'>
                  <img src="/img/logoyeni1.png" alt='' />
                </div>
                <div className='details'>
                 {/*  <h1>Ali Kemal DEMEZ</h1> */}
                  <p></p>
                  <p>Şu an sadece Online Danışmanlık Hizmeti verilmektedir.</p><br />
                  <p></p><br />
                  <p>İletişim: +90 555 844 00 10</p> <br /><br /> <br /> 
                  
                  
                  <span>TAKİP ET</span>
                  <div className='button f_flex'>
                    <button className='btn_shadow'>
                    <i class='fab fa-twitter'></i> 
                    </button>
                    <button className='btn_shadow'>
                      <i className='fab fa-instagram'></i>
                    </button>
                    <button className='btn_shadow'>
                    <i class='fab fa-youtube'></i>
                    </button>                
                              
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              
                <div className='right box_shodow'>
                <form ref={form} onSubmit={sendEmail} className="--form-control--card --flex-center --dir-column">
              
                    <div className='f_flex'>
                  <div className='input row'>
                    <span>ADINIZ</span>
                    <input type='text' name='fullname'  />
                  </div>
                  <div className='input row'>
                    <span>TELEFON NO </span>
                    <input type='number' name='phone' />
                  </div>
                </div>
                <div className='input'>
                  <span>EMAIL </span>
                  <input type='email' name='email'  />
                </div>
                <div className='input'>
                  <span>KONU </span>
                  <input type='text' name='subject'  />
                </div>
                <div className='input'>
                  <span>AÇIKLAMA </span>
                  <textarea cols='30' rows='10' name='message' ></textarea>
                </div>
                <button className='btn_shadow'>
                  GÖNDER <i className='fa fa-long-arrow-right'></i>
                </button>
                <a
                  href="https://wa.me/+905558440010"
                  class="whatsapp_float"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa fa-whatsapp whatsapp-icon"></i>
                </a>
                </form>

                </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
      
      </>
    )
}
export default Contact2

/*
 <div className="container">
                <h2 className="--text-center">Randevu Talep Oluştur</h2>
                <form ref={form} onSubmit={sendEmail} className="--form-control--card --flex-center --dir-column">
              
                    <div className='f_flex'>
                  <div className='input row'>
                    <span>ADINIZ</span>
                    <input type='text' name='fullname'  />
                  </div>
                  <div className='input row'>
                    <span>TELEFON NO </span>
                    <input type='number' name='phone' />
                  </div>
                </div>
                <div className='input'>
                  <span>EMAIL </span>
                  <input type='email' name='email'  />
                </div>
                <div className='input'>
                  <span>KONU </span>
                  <input type='text' name='subject'  />
                </div>
                <div className='input'>
                  <span>AÇIKLAMA </span>
                  <textarea cols='30' rows='10' name='message' ></textarea>
                </div>
                <button className='btn_shadow'>
                  GÖNDER <i className='fa fa-long-arrow-right'></i>
                </button>
                <a
                  href="https://wa.me/+905075883110"
                  class="whatsapp_float"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa fa-whatsapp whatsapp-icon"></i>
                </a>
                </form>
            </div>

* */



/* const Contact2 = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_hn0ykp2', 'template_3goiro9', form.current, 'jNiZ91aw5HdnkIu63')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          e.target.reset()
      };
    return (
        <section className='Contact' id='contact'>
        <div className='container top'>
          <div className='heading text-center'>
            <h4>CONTACT</h4>
            <h1>Benimle İletişime Geçin</h1>
            <h4>Şuan sadece Online Danışmanlık Hizmeti Verilmektedir.</h4>
          </div>

          <div className='content d_flex'>
            <div className='left'>
              <div className='box box_shodow'>
                <div className='img'>
                  <img src={contact1} alt='' />
                </div>
                <div className='details'>
                  <h1>Ali Kemal DEMEZ</h1>
                  <p>Dr. Aile Danışmanı</p>
                  <p>Yüzyüze ve Online Seanslarımız vardır.</p> <br />
                  <p>Phone: +09 507 588 31 10</p>
                  <p>Email: admin@example.com</p> <br />
                  <span>TAKİP ET</span>
                  <div className='button f_flex'>
                    <button className='btn_shadow'>
                      <i className='fab fa-facebook-f'></i>
                    </button>
                    <button className='btn_shadow'>
                      <i className='fab fa-instagram'></i>
                    </button>
                    <button className='btn_shadow'>
                      <i className='fab fa-twitter'></i>
                    </button>                
                              
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
                <h2 className="--text-center">Randevu Talep Oluştur</h2>
                <form ref={form} onSubmit={sendEmail} className="--form-control--card --flex-center --dir-column">
              
                    <div className='f_flex'>
                  <div className='input row'>
                    <span>ADINIZ</span>
                    <input type='text' name='fullname'  />
                  </div>
                  <div className='input row'>
                    <span>TELEFON NO </span>
                    <input type='number' name='phone' />
                  </div>
                </div>
                <div className='input'>
                  <span>EMAIL </span>
                  <input type='email' name='email'  />
                </div>
                <div className='input'>
                  <span>KONU </span>
                  <input type='text' name='subject'  />
                </div>
                <div className='input'>
                  <span>AÇIKLAMA </span>
                  <textarea cols='30' rows='10' name='message' ></textarea>
                </div>
                <button className='btn_shadow'>
                  GÖNDER <i className='fa fa-long-arrow-right'></i>
                </button>
                <a
                  href="https://wa.me/+905075883110"
                  class="whatsapp_float"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fa fa-whatsapp whatsapp-icon"></i>
                </a>
                </form>
            </div>
          </div>
        </div>
      </section>
    )
}* */