import React from "react"
import './Home.css'
//import hero from '../pic/logoşeffaf.png'
import Features from "../Features/Features"
//import Testimonial from "../Testimonial/Testionial"
import Contact2 from "../Contact2/Contact2"
import { Typewriter } from "react-simple-typewriter"
import video from "../../assets/pexels-peter-fowler-3161307-1280x720-24fps.mp4"


const Home = () => {
    return (
      <>
     <div className="main">
      <video src={video} autoPlay loop muted/>
     </div>
     <div className="section">

        <section className='hero' id='home'>
          <div className='container f_flex top'>
            <div className='left top'>
              <h3><span>PsikoHayal</span> Danışmanlık Merkezine HOŞGELDİNİZ</h3>
          {/*     <h1>
               Merhaba Benim Adım <span>Ali Kemal</span>
              </h1> */}
              <h2>
                
                <span>
                <Typewriter
            words={['Birey / Yetişkin Danışmanlığı','Aile / Çift Danışmanlığı', 'İlişki / Evlilik Danışmanlığı','Ebeveyn Danışmanlığı','Ergen / Eğitim Danışmanlığı', 'Tıbbi Danışmanlık','Online Danışmanlık']}
            loop
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          
          />
                </span>
              </h2>
  
         {/*      <p>Aile Danışmanlığı, Yetişkin danışmanlığı ve Eğitim danışmanlık alanlarında yüzyüze ve online danışmanlık hizmetleri verilmektedir.</p> */}
  
              <div className='hero_btn d_flex'>
                <div className='col_1'>
                  <h4>BİZİ TAKİP EDİN</h4>
                  <div className='button'>
                    <button className='btn_shadow'>
                    <i class='fab fa-twitter'></i>
                    </button>
                    <button className='btn_shadow'>
                      <i class='fab fa-instagram'></i>
                    </button>
                    <button className='btn_shadow'>
                    <i class='fab fa-youtube'></i>
                    </button>
                  </div>
                </div>
                
              </div>
            </div>
      

            {/* <div className='right'>
              <div className='right_img'>
                <img src={hero} alt='' />
              </div>
            </div> */}
          </div>
        </section>
     </div>
      
        <Features/>
        
       
        <Contact2/>
        
      </>
    )
  }
  
  export default Home