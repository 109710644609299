
const data = [
    {
      id: 1,
      image: '/img/6.png',
      title: "Birey / Yetişkin Danışmanlığı",
      short:"Bireysel Danışmanlıkta öncelikle kişinin kendisini tanıması, maddi-manevi donanımlarının ve güçlü-zayıf yanlarının farkına varması amaçlanır.Bu farkındalık ..",
      desc: "Bireysel Danışmanlıkta öncelikle kişinin kendisini tanıması, maddi-manevi donanımlarının ve güçlü-zayıf yanlarının farkına varması amaçlanır. Bu farkındalık temelinde bireyin problemlerinin, duygulanımlarının, davranışlarının, tepkilerinin değerlendirmesi yapılır ve çözüm stratejisi danışman ve danışan tarafından birlikte belirlenir. Karşılıklı güven, gizlilik ve etik ilkeler çerçevesinde danışmanlık süreci, bireyin kendi kendine problem çözme becerileri ve olumlu-olumsuz durumlara farklı bakış açıları kazandırma hedefiyle sürdürülür. ",
    },
    {
      id: 2,
      image: "/img/2.png",
      title: "Aile / Çift Danışmanlığı",
      short:"Aile, bir bakış açısıyla da farklı özelliklere sahip bireylerin bir arada yaşadığı sistemlerdir.Her ailenin kendine özgü dinamikleri, bağları ve dengesi mevcuttur fakat bunlar .. ",
      desc: " Aile, bir bakış açısıyla da farklı özelliklere sahip bireylerin bir arada yaşadığı sistemlerdir. Her ailenin kendine özgü dinamikleri, bağları ve dengesi mevcuttur fakat bunlar gerekli özenin gösterilmemesi ya da olağanüstü durumların yaşanması durumlarında bozulabilir, yıpranabilir hatta kopma noktasına gelebilir. Bu sürecin sağlıklı değerlendirmesini yapmak, sorun ve çözümleri tespit etmek; özellikle içeriden gözlerle bunları gerçekleştirmek çoğu zaman mümkün olmayabilir. İşte bu ve benzer durumlarda Suçlayıcı ve Yargılayıcı bir dil kullanmayan, Tarafsız kalabilen, Empati Yeteneğine sahip bir Profesyonel Danışman moderatörlüğünde sürecin yönetilmesi, tüm aile bireylerinin bir bütün olarak kazanç sağlayacağı en uygun, en isabetli seçenek olarak karşımıza çıkmaktadır. ",
    },
    {
      id: 3,
      image: "/img/7.png",
      title: "İlişki / Evlilik Danışmanlığı",
      short:"İlişkilerin ve Evliliğin dinamikleri, tarafların beklentileri, normal arkadaşlıktan çok farklıdır. Bireysel, Kültürel, Entelektüel, Çevresel ve İnançsal farklılıklar başta olmak üzere birçok etken, bu..",
      desc: " İlişkilerin ve Evliliğin dinamikleri, tarafların beklentileri, normal arkadaşlıktan çok farklıdır. Bireysel, Kültürel, Entelektüel, Çevresel ve İnançsal farklılıklar başta olmak üzere birçok etken, bu sürece olumlu ya da olumsuz katkılar sağlar. Buradaki püf nokta, tarafların yapacağı mücadelenin, dokunuşların huzuru inşa etmek adına birbirlerini değiştirmeye çalışmak yönünde değil; farklı özellikleri, bakış açıları ve iletişim dilleri konusunda farkındalık kazanmak ve sınırlarını keşfedip bu sınırlara saygı duyarak ilişkilerini, evliliği temellendirmek olmalıdır.",
    },
    {
      id: 4,
      image: "/img/8.png",
      title: "Ebeveyn Danışmanlığı",
      short:"'Bir bardak duvara atılırsa kırılır' diyen biri, bütün bardakları cam bardak olarak kabul etmektedir; zira karton bardağın duvara atıldığında kırılmayacağı malumdur. Nasıl farklı..",
      desc: "'Bir bardak duvara atılırsa kırılır' diyen biri, bütün bardakları cam bardak olarak kabul etmektedir; zira karton bardağın duvara atıldığında kırılmayacağı malumdur. Nasıl farklı tip bardaklar aynı muameleye maruz kaldıklarında farklı sonuçlar ortaya çıkıyor ise farklı çocuklara aynı kalıpla yaklaştığımızda da olumlu ya da olumsuz farklı sonuçlar ortaya çıkacaktır. Bu yüzden her çocuğa aynı davranmak yerine, çocuğa özel uygun davranış modeli geliştirmek ideale daha yakın olandır. Birçok ebeveyn bazen farkında olmadan bazen bilgi eksikliğinden bazen yanlış inanç kalıplarından bazen hayat yoğunluğundan bazen de bulundukları ortamın şartlarından dolayı çocuklarına uygunsuz yaklaşımlarda, söylem ve eylemlerde bulunabilmektedir. Bütün bu durumların bazen erken bazen de geç dönemlerde çocukta yansımaları gerçekleşmektedir. Ebeveynlik sürecinin sağlıklı yönetilmesi adına hem çocuğun kendisi hem de toplum için bizler yardımcı olmaya, bilgi ve tecrübelerimizi aktarmaya, bakış açıları sunmaya hazırız. ",
    },
    {
      id: 5,
      image: "/img/4.png",
      title: " Ergen / Eğitim Danışmanlığı  ",   
      short:"Psikolojik ve Fiziksel olarak en hızlı değişimlerin yaşandığı dönem olan ergenlik dönemindeki ergen bireylere, geçirdikleri dönemin dinamiklerine uygun olarak ..",
      desc: "Psikolojik ve Fiziksel olarak en hızlı değişimlerin yaşandığı dönem olan ergenlik dönemindeki ergen bireylere, geçirdikleri dönemin dinamiklerine uygun olarak yaklaşım sağlanmalıdır. Bir ergenle ilk olarak ortak iletişim dili belirlenip, güven ilişkisi kurulmalı; sonrasında ergen bireyin tanınması, olumlu-olumsuz tepki mekanizması ve potansiyelinin keşfedilmesi amaçlanmalıdır. Müteakiben danışmanlık sürecine getirilen probleme yönelik ergen bireyin yapısına uygun olarak, birey ile beraber strateji planlaması yapılır. Ergen bireyler aynı zamanda yaş itibariyle eğitim sürecine devam ettikleri için, bu dönemdeki problemler ergen bireylerin ders başarılarını ve okul içi ilişkilerini de etkilemektedir. Etkili iletişim ve güvenli bağ kurma temelinde planlanacak akılcı müdahaleler ile bu sürecin mümkün olan en sağlıklı şekilde geçirilmesi çoğu zaman mümkündür.  ",
    },
    {
      id: 6,
      image: "/img/66.png ",
      title: "Tıbbi Danışmanlık",
      short:"İnsanların sağlıkla ilgili sorunlarını anlamalarına, tedavi seçeneklerini değerlendirmelerine ve sağlık hizmetlerini en iyi şekilde kullanmalarına yardımcı olmak amacıyla sağlanan profesyonel ..",
       desc:  " İnsanların sağlıkla ilgili sorunlarını anlamalarına, tedavi seçeneklerini değerlendirmelerine ve sağlık hizmetlerini en iyi şekilde kullanmalarına yardımcı olmak amacıyla sağlanan profesyonel bir hizmet olan Tıbbi Danışmanlık, kişinin sağlık durumunu en iyi şekilde yönetmesine yardımcı olur. Bu kapsamda Laboratuvar tetkikleri ve Radyolojik Görüntüleme raporlarının değerlendirilmesi ile ilgili branşlara uygun yönlendirmeler yapılarak, tanı ve tedaviye ulaşmada gecikmeler önlenir. Aynı zamanda koruyucu sağlık hizmetleri alanında da kişiye özel öneriler sunulur.   ", 
      
    },
  ]
  export default data