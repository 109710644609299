import React from "react"
import "./Features.css"
import Card from "./Card"
import data from './FeaturesApi'

const Features = () => {
    return (
        <>
            <section className='features top' id='features'>
                <div className='container'>
                    <div className='heading'>
                        {/* <h4>Alanlar</h4> */}
                        <h1>Hizmetlerimiz</h1>
                    </div>

                    <div className='content grid'>
                    {data.map((val, index) => {
              return <Card key={index} image={val.image} title={val.title} desc={val.desc} short={val.short} />
            })}
                      
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features