import React, { useState } from "react"

const Card = (props) => {
  const [modal, setModal] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  if (modal) {
    document.body.classList.add("active-modal")
  } else {
    document.body.classList.remove("active-modal")
  }
  return (
    <>
      <div className='box btn_shadow ' onClick={toggleModal}>
        <div className='img'>
          <img src={props.image} alt=''  />
        </div>

        <div className='title'>
          <h2 >{props.title}</h2>
          <p >{props.desc1}</p> 
          <p>{props.short}</p>        
       
        </div>
        <a href='#' >
          <i className='fas fa-arrow-right'></i>
        </a>
      </div>

      {/* Popup box */}
      {modal && (
        <div className='modal modal-blog'>
          <div onClick={toggleModal} className='overlay'></div>
          <div className='modal-content'>
            <div className='modal-img left'>
              <img src={props.image} alt='' />
            </div>
            <div className='modal-text right'>
             
              <h1>{props.title}</h1>
              <p>{props.desc}</p>
        {/*       <ul>
                <li>{props.li1}</li>
                <li>{props.li2}</li>
                <li>{props.li3}</li>
                <li>{props.li4}</li>
                <li>{props.li5}</li>
                <li>{props.li6}</li>
              </ul> */}

          

              <button className='close-modal btn_shadow' onClick={toggleModal}>
                <i class='fas fa-times'></i>
              </button>


            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Card